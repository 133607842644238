import axios from 'axios'
const API = 'servicios-manager'
const Servicios = {
    getListAllServices(params){
      return axios.get(`${API}/lista`,{params})
    },
    getDetalleServicio(idServicio){
      return axios.get(`${API}/${idServicio}/detalle-servicio`)
    },
    getConjuntosConElServicio(idServicio, params){
      return axios.get(`${API}/${idServicio}/conjuntos/cobertura`, {params})
    },
    getListaVisitasResidentes(idServicio, params){
      return axios.get(`${API}/${idServicio}/visitas-residentes`,{params})
    },
    crearServicio(payload){
      return axios.post(`${API}/crear`,payload)
    },
    gestionDisponibilidadServicioConjunto(idServicio, payload){
      return axios.post(`${API}/gestion-servicios/${idServicio}/conjuntos`, payload)
    },
    actualizarPosicionServicios(payload){
      return axios.put(`${API}/actualizar-posicion`,payload)
    },
    actualizarInfoServicios(payload){
      return axios.put(`${API}/completar-datos/servicio`,payload)
    },
    actualizarInfoEnCaliente(payload){
      return axios.put(`${API}/editar/logo-banner-descripcion`,payload)
    },
    deleteService(idServicio){
      return axios.delete(`${API}/${idServicio}/eliminar`)
    },
    actualizarVisibilidad(payload){
      return axios.put(`${API}/actualizar-visibilidad`,payload)
    },

    listarCategorias(params){
      return axios.get(`${API}/categorias`,{params})
    },
    actualizarPosicion(payload){
      return axios.put(`${API}/categoria/actualizar-posicion`,payload)
    },
    crearCategoria(payload){
      return axios.post(`${API}/crear/categoria`,payload)
    },
    deleteCategoria(params){
      return axios.delete(`${API}/eliminar/categoria`,{params})
    },
    detalleCategoria(params){
      return axios.get(`${API}/detalle/categoria`,{params})
    },
    editarCategoria(payload){
      return axios.put(`${API}/editar/categoria`,payload)
    },
    getConjuntosCiudades(){
      return axios.get(`${API}/ciudades`)
    },
}
export default Servicios