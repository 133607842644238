<template>
    <section class="main-categorias-menus d-flex">
        <div class="menu-left">
			<div class="d-middle pl-2 pr-1 border-bottom py-3 mx-1">
				<p>Categorías</p>
				<el-tooltip content="Nueva categoría" effect="light" :visible-arrow="false">
					<div class="btn-create-c d-middle-center br-5 border bg-general br-4 border cr-pointer ml-auto" @click="openModalCreateCategory">
						<i class="icon-añadir f-15 text-white " />
					</div>
				</el-tooltip>
				<el-popover placement="bottom" trigger="hover" :visible-arrow="false">
					<div class="row align-items-center cr-pointer item mx-0 text-black px-2 br-4" style="height:25px;" @click="openModalOrderCategories">
						Ordenar categorías
					</div>
					<div slot="reference" class="br-4 border btn-action cr-pointer ml-2">
						<i class="icon-opciones f-15 text-black" />
					</div>
				</el-popover>
			</div>
			<div class="listado">
				<div v-for="(item, idx) in categorias" :key="idx" class="row mx-0 px-2 my-3" @click="cargarCategoria">
                    <router-link :to="{name: 'tiendas-proveedores', params: {idCategoria: item.id}}" class="link-categories d-middle w-100 p-1 px-2">
						<img :src="item.imagen" alt="" width="40" height="40">
						<p class="col pl-2 pr-2">{{ item.nombre }}</p>
					</router-link>
                </div>
			</div>
		</div>
		<div class="section-right">
			<router-view @actualizar="actualizarVista"/>
			<div v-if="categoria" class="text-center text-black-50 justify-content-center h-100 d-middle-center">
				<div>
					<img src="/img/estados-vacios/categorias_servicios.svg" class="obj-cover " style="max-width:100%;max-height:100%;" />
					<p class="pl-2 pr-2 mt-2">Selecciona o crea una nueva categoría</p>
				</div>
			</div>
		</div>
		<!-- partials -->
		<modalCreateCategory ref="openModalCreateCategory" @actualizar="getCategorias" />
		<modalOrderCategories ref="openModalOrderCategories" />
    </section>
</template>
<script>
import moment from 'moment'
import Categorias from '~/services/tiendas'
export default {
	components: {
        modalCreateCategory: () => import('./partials/modalCreateCategory'),
		modalOrderCategories: () => import('./partials/modalOrderCategories'),
    },
    data(){
        return {
			menuConf:[
				{
					id: 1,
					img: 'https://mi-conjunto-sandbox.s3.amazonaws.com/servicios/91212018763038ce5f0e3f3.636215420.986693001661177061.jpeg',
					titulo: 'prueba',
				},
				{
					id: 2,
					img: 'https://mi-conjunto-sandbox.s3.amazonaws.com/servicios/91212018763038ce5f0e3f3.636215420.986693001661177061.jpeg',
					titulo: 'prueba 2',
				},
			],
			categorias: [],
			categoria: true,
        }
    },
    
    mounted(){
		this.getCategorias();
    },
    methods: {
		async getCategorias(){
			try {				
				const { data } = await Categorias.listarCategorias()
				this.categorias = data.data;
			} catch (error) {
				this.error_catch(error)
			}
		},
		openModalCreateCategory(){
			this.$refs.openModalCreateCategory.toggle()
		},
		openModalOrderCategories(){
			this.$refs.openModalOrderCategories.toggle(this.categorias)
		},
		cargarCategoria(){
			this.categoria = false;
		},
		actualizarVista(){
			this.categoria = true;
			this.getCategorias();
		}
    }
}
</script>
<style lang="scss" scoped>
.main-categorias-menus{
	.btn-create-c{
		height: 32px;
		width: 32px;
	}
	height: calc(100vh - 79px);
    //overflow: auto;
	//height: calc(100vh - 71px);
	.menu-left{
		min-width: 250px;
		background-color: #FAFBFC;
		box-shadow: 1px 0px 2px #00000029;
		.options{
			max-width: 32px;
			min-width: 32px;
			max-height: 32px;
			min-height: 32px;
			background: transparent linear-gradient(180deg, #FFFFFF 0%, #F3F5F7 100%) 0% 0% no-repeat padding-box;
			border: 1px solid #CFD7DF;
			border-radius: 4px;
		}
	}
	.section-right{
		width: calc(100vw - 270px) ;
	}
	.link-categories{
		text-decoration: none !important;
		font-size: 13px;
		&:hover{
			background-color: #EBECF0;
			text-decoration: none !important;
		}
		&.router-link-active{
			background-color: #EBECF0;
			border-radius: 4px;
			color: #5A80EA !important;
			font-weight: 500;
		}
		//background-color: red;
	}
}
</style>